import { AxiosInstance, RawAxiosResponseHeaders } from "axios";

/** Custom response type created knowing that our only backend returns data, headers and statusCode
 * @typedef {ApiResponse}
 * @property {ApiSearchResponseData} data - the response data
 * @property {AxiosResponseHeaders} headers - the response headers
 * @property {number} statusCode - the status code of the response (ex: 200, 402, 500 etc.)
 */
export type ApiResponse = {
    data: any;
    headers: RawAxiosResponseHeaders;
    statusCode: number;
};

/**
 * @function asyncPost
 * @returns {Promise<ApiResponse>}
 * with this function we use the custom axios instance to create the generalized post method to a backend service
 * this asyncPost will receive specific parameters at the API call declaration (see petd/index.ts)
 * @param {AxiosInstance} axiosInstance - custom axios instance
 * @param {string} url - the entire url (not just the base we set in the axios instance)
 * @param {Record<string, unknown> | Record<string, unknown>[]} data - the payload of the request
 */
export const asyncPost = (
  axiosInstance: AxiosInstance,
  url: string,
  data?: Record<string, unknown> | Record<string, unknown>[],
): Promise<ApiResponse> =>
  axiosInstance.post(url, data).then((r) => ({ data: r?.data, headers:r?.headers , statusCode:r?.status  }));

  /**
 * @function asyncGet
 * @returns {Promise<ApiResponse>}
 * with this function we use the custom axios instance to create the generalized get method to a backend service
 * this asyncGet will receive specific parameters at the API call declaration (see petd/index.ts)
 * @param {AxiosInstance} axiosInstance - custom axios instance
 * @param {string} url - the entire url (not just the base we set in the axios instance)
 */
export const asyncGet = (
  axiosInstance: AxiosInstance,
  url: string,
): Promise<ApiResponse> =>
  axiosInstance.get(url).then((r) => ({ data: r?.data, headers: r?.headers, statusCode: r?.status }));

   /**
 * @function asyncDelete
 * @returns {Promise<ApiResponse>}
 * with this function we use the custom axios instance to create the generalized delete method to a backend service
 * this asyncDelete will receive specific parameters at the API call declaration (see petd/index.ts)
 * @param {AxiosInstance} axiosInstance - custom axios instance
 * @param {string} url - the entire url (not just the base we set in the axios instance)
 */
export const asyncDelete = (
  axiosInstance: AxiosInstance,
  url: string,
): Promise<ApiResponse> =>
  axiosInstance.delete(url).then((r) => ({ data: r?.data, headers: r?.headers, statusCode: r?.status }));
