import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
//Components call
import { Header } from "components/common/Header";
import { ButtonComp } from "components/common/ButtonComp";
import { MyPagination } from "../../utils/MyPagination.js";
//Bootstrap comoponent call
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
//Content to display and data call
import { content as CONTENT } from "content/content";
import { requests } from "data/mockData";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ButtonList } from "components/common/ButtonList";
//Imports for backend call
import { apiClient } from "api/index";
import { store } from "store/store";

/**
 * Component displaying a table of the different requests made
 *
 * @returns {React.ReactElement} a page table displaying the differents requests
 */
export const RequestList: React.FC = React.memo(() => {
  RequestList.displayName = "RequestList";

  const navigate = useNavigate();
  const location = useLocation();
  var display = location?.state?.display;
  var error = location?.state?.error;

  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);

  const {
        documentsRecipient,
        setDocumentsRecipient,
        requestDate,
        setRequestDate,
        requestLabel,
        setRequestLabel,
        requestStatus,
        setRequestStatus,
        requestType,
        setRequestType,
        requestID,
        setRequestID,
  } = useContext(store);

  const petdSearchRequestData = {
    documentsRecipient: documentsRecipient,
    requestDate: requestDate,
    requestLabel: requestLabel,
    requestStatus: requestStatus,
    requestType: requestType
  };

  const sendSearchRequest = async () => {
    const { data } = await apiClient().petd.postSearchRequest(petdSearchRequestData);
    const searchResults = /^true$/i.test((window as unknown as WindowWithEnv)?._env_?.useMock || "false")
          ? requests
          : data;
    setSearchResults(searchResults);
  };

  useEffect(() => {
    sendSearchRequest();
  }, [documentsRecipient, requestDate, requestLabel, requestStatus, requestType])

  useEffect(() => {
    window.history.replaceState({state: null}, document.title );
   }, [])

  //Number of displayed rows in the table
  let pageSize = 10;

  /**
   * Update data according pagination
   */
  const currentData = useMemo(() => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return searchResults?.slice(firstPageIndex, lastPageIndex);
  }, [pageSize, page, searchResults]);

  /**
   * Update page number according item pagination
   */
  const handleChangePage = useCallback((page: React.SetStateAction<number>) => {
    setPage(page);
  }, []);

  return (
    <React.Fragment>
      <Header pageTitle={CONTENT.header.req_list} />
      {display!=null ? (
        
        <div className='w-75 mx-auto pt-3'>
          <div className='border border-success flex-wrap text-center'>
            <p className="mb-1 mt-1">{display}</p>
          </div>
        </div>
      ) : null}
      {error!=null ? (
        <div className='w-75 mx-auto pt-3'>
          <div className='border border-danger flex-wrap text-center'>
            <p className="mb-1 mt-1">{error}</p>
          </div>
        </div>
      ) : null}
      {/* Encapsulation of the bootstrap Table component to ensure a maximum display size */}
      <div className='wide-container table-container'>

        <Table
          bordered
          hover
        >
          {/* Table header with the differents column labels and associated input to ease the search */}
          <thead>
            <tr>
              <th>
                {CONTENT.req_list.date}
                <Form.Control
                    type='date'
                    value={requestDate}
                    onChange={(e) => {
                      setRequestDate(e.target.value === "" ? undefined : e.target.value as string);
                    }}
                />
              </th>

              <th>
                {CONTENT.req_list.type}
                <Form.Select
                  name='type'
                  value={requestType}
                  onChange={(e) => {
                    setRequestType(e.target.value === "" ? undefined : e.target.value as string);
                  }}
                >
                  <option value=''>Choisir une valeur</option>
                  <option value='RJS_APA'>RJS_APA</option>
                  <option value='RJS_NPL'>RJS_NPL</option>
                  <option value='RJS_NPL_CMOI'>RJS_NPL_CMOI</option>
                  <option value='RJS_SRDT_NPL'>RJS_SRDT_NPL</option>

                </Form.Select>
              </th>
              <th>
                {CONTENT.req_list.dest}
                <Form.Control
                  type='text'
                  value={documentsRecipient}
                  onChange={(e) => {
                    setDocumentsRecipient(e.target.value === "" ? undefined : e.target.value as string);
                  }}
                />
              </th>
              <th>
                {CONTENT.req_list.status}
                <Form.Control
                  type='text'
                  value={requestStatus}
                  onChange={(e) => {
                    setRequestStatus(e.target.value === "" ? undefined : e.target.value as string);
                  }}
                />
              </th>

              <th>
                {CONTENT.req_list.label}
                <Form.Control
                  type='text'
                  value={requestLabel}
                  onChange={(e) => {
                    setRequestLabel(e.target.value === "" ? undefined : e.target.value as string);
                  }}
                />
              </th>
              <th>{CONTENT.req_list.nb_dir}</th>
            </tr>
          </thead>

          {/* Table body where all the data are called and display in their associated column */}
          <tbody>
            {currentData?.map((resp: any) => {
              return (
                <tr
                  onClick={() => {
                    setRequestID(resp.requestId);
                    navigate("/reqPending", {state: null}) }}>
                  <td>{resp.requestDate}</td>
                  <td>{resp.requestType}</td>
                  <td>{resp.documentsRecipient}</td>
                  <td>{resp.requestStatus}</td>
                  <td>{resp.requestLabel}</td>
                  <td>{resp.nbContract}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        {searchResults?.length > 1 && (
          <MyPagination
            total={searchResults?.length}
            current={page}
            onChangePage={handleChangePage}
            pageSize={pageSize}
          />
        )}
        
        <ButtonList
          slotF={
            
            <Link to='/create'>{sessionStorage.getItem('authtype')==="interne"?(
              <ButtonComp
                btnLabel={CONTENT.buttons.create}
                variant='primary'
                size='lg'
              />):null}
            </Link>
            
          }
        />
        
      </div>
    </React.Fragment>
  );
});

