import { petdApi } from "./petd";

/**
 * @function apiClient
 * here we add oll the APIs of our backend service (in this case, only one - petdApi)
 */
export function apiClient() {
  return Object.freeze({
    petd: petdApi(),
  });
}
