import { axiosInstance } from '../axios';
import { ApiResponse, asyncPost, asyncGet, asyncDelete } from "../utils";

const petdPath = `v1/petd`;

/**
 * @function petdApi
 * here we declare all the calls of our API
 * we pass the specific parameters to the custom generic asyncPost, asyncGet or asyncDelete methods
 */
export function petdApi() {
  return Object.freeze({
    postSearchRequest: (payload: any) : Promise<ApiResponse> =>
      asyncPost(axiosInstance(),`${encodeURI(`${petdPath}/request/search`)}`, payload),
    
    postCreateRequest: (payload: any) : Promise<ApiResponse> =>
      asyncPost(axiosInstance(), `${encodeURI(`${petdPath}/request`)}`, payload),

    getReadRequestArchive: (requestId: any) : Promise<ApiResponse> =>
      asyncGet(axiosInstance(),`${encodeURI(`${petdPath}/request/archiveInfo/${requestId}`)}`),

    getDownloadRequestArchive: (requestId: any, payload: any) : Promise<ApiResponse> =>
      asyncPost(axiosInstance(), `${encodeURI(`${petdPath}/request/archiveFile/${requestId}`)}`, payload),

    deleteRequest: (requestId: any) : Promise<ApiResponse> =>
      asyncDelete(axiosInstance(), `${encodeURI(`${petdPath}/request/${requestId}`)}`),

    getRetrieveFileRequest: (requestId: any) : Promise<ApiResponse> =>
      asyncGet(axiosInstance(), `${encodeURI(`${petdPath}/request/file/${requestId}`)}`),

    postUpdateRequest: (requestId: any, payload: any) : Promise<ApiResponse> =>
      asyncPost(axiosInstance(), `${encodeURI(`${petdPath}/request/${requestId}`)}`, payload),

    getReadDetailRequest: (requestId: any) : Promise<ApiResponse> =>
    asyncGet(axiosInstance(), `${encodeURI(`${petdPath}/request/${requestId}`)}`),
    
    getRetrieveReportRequest: (requestId: any) : Promise<ApiResponse> =>
      asyncGet(axiosInstance(), `${encodeURI(`${petdPath}/request/report/${requestId}`)}`),
  });

}
